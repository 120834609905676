import { Expose, plainToClass } from 'class-transformer';
import { StatusNotificacao } from '.';
import { PayloadNotificacaoDto } from './payload-notificacao.dto';
import { TipoNotificacao } from './tipo-notificacao.enum';

export type NotificacaoPushData = {
  notificacaoId: string;
  notificacaoTipo: TipoNotificacao;
  cadastroId?: string;
  usuarioId: string;
};

export class NotificacaoDto {
  @Expose()
  id: string;

  @Expose()
  proprietario?: string;

  @Expose()
  payload?: PayloadNotificacaoDto;

  @Expose()
  titulo: string;

  @Expose()
  descricao: string;

  @Expose()
  status: StatusNotificacao;

  @Expose()
  tipo: TipoNotificacao;

  @Expose()
  dataEnvio: Date;

  @Expose({ name: 'cadastro_id' })
  cadastroId?: string;

  @Expose({ name: 'usuario_backoffice' })
  usuarioBackoffice?: boolean;

  /**
   * Cria uma nova instância de NotificacaoDto a partir de dtos
   * @param props DTO de Notificação
   * @returns nova instância de NotificacaoDto
   */
  static from(props?: NotificacaoDto | Partial<NotificacaoDto>): NotificacaoDto {
    return plainToClass(NotificacaoDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
