/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterContentInit,
  Component, EventEmitter, Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { LoteListarMapaDto } from '@usucampeao/lib-reurb-simplificado';
import { COR_LOTE, COR_LOTE_SELECIONADO } from '../mapa.component';

interface PoligonoLote {
  loteId: number;
  poligono: google.maps.PolygonOptions;
  paths: google.maps.LatLng[];
}

@Component({
  selector: 'usucampeao-mapa-google',
  templateUrl: './mapa-google.component.html',
  styleUrls: ['./mapa-google.component.scss'],
})
export class MapaGoogleComponent implements AfterContentInit, OnChanges {
  @Input() lotes: LoteListarMapaDto[] = [];
  @Input() loteSelecionadoId: number;
  @Input() lat: number;
  @Input() lng: number;
  @Input() mostrarMarker = false;
  @Input() zoomInicial: number;
  @Input() desabilitado = false;

  @Output() aoSelecionarLote = new EventEmitter<LoteListarMapaDto>();

  private indiceSelecionado = -1;

  public poligonos: PoligonoLote[] = [];
  public mapaOptions: google.maps.MapOptions;
  public centerGoogleMaps!: google.maps.LatLngLiteral;
  public markerGoogle: google.maps.LatLngLiteral;
  public height = '100%';

  ngAfterContentInit(): void {
    this.gerarMapa();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { lat, lng } = changes;
    if (lat && lng && ((!lat.firstChange && !lng.firstChange) || this.mostrarMarker)) {
      this.atualizarMarker({ lat: lat.currentValue, lng: lng.currentValue });
    }
  }
  public get mapaHeight(): string {
    if (window.innerWidth <= 630) {
      return '80%';
    }

    return '100%';
  }

  /**
   * Constrói o mapa do Google Maps
   */
  private gerarMapa(): void {
    this.mapaOptions = {
      zoom: this.zoomInicial,
      mapTypeId: google.maps.MapTypeId.HYBRID,
      gestureHandling: 'greedy',
      mapTypeControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT
      }
    };
    this.centerGoogleMaps = {
      lat: this.lat,
      lng: this.lng
    };

    this.buildLotesGoogle();
  }

  private buildLotesGoogle(): void {
    this.poligonos = this.lotes
      .map((lote, index) => {
        const loteSelecionado = this.loteSelecionadoId === lote.id;
        const paths = lote.coords.coordinates[0].map(coord => new google.maps.LatLng(coord[0], coord[1]));
        const poligono = {
          strokeColor: loteSelecionado ? COR_LOTE : COR_LOTE_SELECIONADO,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: loteSelecionado ? COR_LOTE : COR_LOTE_SELECIONADO,
          fillOpacity: 0.35,
        };

        if (loteSelecionado) {
          this.indiceSelecionado = index;
          if (paths.length > 0) {
            const somaLatLng = paths.reduce((acc, curr) => {
              return [acc[0] + curr.lat(), acc[1] + curr.lng()]
            }, [0, 0]);
            this.centerGoogleMaps = {
              lat: somaLatLng[0] / paths.length,
              lng: somaLatLng[1] / paths.length,
            };
            this.mapaOptions.zoom = 20;
          }
        }

        return {
          loteId: lote.id,
          poligono,
          paths
        };
      });
  }

  public trackByLoteAndColor(_: number, data: PoligonoLote): string {
    return `${data.loteId} - ${data.poligono.fillColor}`
  }

  public selecionarLote(data: PoligonoLote, index: number): void {
    if (this.desabilitado) {
      return;
    }

    if (this.indiceSelecionado > -1) {
      this.poligonos[this.indiceSelecionado].poligono.fillColor = COR_LOTE_SELECIONADO;
      this.poligonos[this.indiceSelecionado].poligono.strokeColor = COR_LOTE_SELECIONADO;
    }

    this.loteSelecionadoId = data.loteId;
    this.indiceSelecionado = index;
    data.poligono.strokeColor = COR_LOTE;
    data.poligono.fillColor = COR_LOTE;
    const lote = this.lotes.find(lote => lote.id === this.loteSelecionadoId);
    this.aoSelecionarLote.emit(lote);
  }

  public atualizarMarker(latLng: google.maps.LatLngLiteral): void {
    this.centerGoogleMaps = latLng;
    this.markerGoogle = latLng;
  }

  public clearMarker(): void {
    this.markerGoogle = null;
  }
}
