<ion-content class="ion-padding">
  <ion-refresher slot="fixed" (ionRefresh)="carregarCadastro($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <usucampeao-contrato-selecionar-forma-pagamento *ngIf="!!cadastro"
    [possuiPagamento]="!projeto.semPagamento" [cadastro]="cadastro"
    [tabelaPreco]="tabelaPreco" (aoSelecionarFormaPagamento)="aoSelecionarFormaPagamento($event)">
  </usucampeao-contrato-selecionar-forma-pagamento>
</ion-content>
