/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import {
  CupomDescontoTipoDesconto,
  DiaPagamento,
  MeioPagamento,
  PaymentType,
  TabelaPrecoDetalhesDto,
  TabelaPrecoParcela,
  TabelaPrecoParcelaListaDto
} from '@usucampeao/lib-reurb-simplificado';
import { AbstractForm } from '@usucampeao/utils-frontend';
import { Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { CadastroStateDto } from '../../../domain';
import { CupomDescontoService } from '../../../services/cupom-desconto.service';
import { LoadingService, ToastService } from '../../../shared';
import { ModalAdicionarCupomDescontoComponent } from './modal-adicionar-cupom-desconto/modal-adicionar-cupom-desconto.component';

export type ContratoSelecionarFormaPagamentoOutput = {
  diaEntrada: DiaPagamento;
  diaVencimento: DiaPagamento;
  totalParcelas: number;
  meioPagamento: MeioPagamento;
}

@Component({
  selector: 'usucampeao-contrato-selecionar-forma-pagamento',
  templateUrl: './contrato-selecionar-forma-pagamento.component.html',
  styleUrls: ['./contrato-selecionar-forma-pagamento.component.scss']
})
export class ContratoSelecionarFormaPagamentoComponent extends AbstractForm implements OnInit, ViewWillEnter, OnChanges, OnDestroy {
  @Input() cadastro: CadastroStateDto;
  @Input() possuiPagamento: boolean;
  @Input() tabelaPreco: TabelaPrecoDetalhesDto[];

  @Output() aoSelecionarFormaPagamento = new EventEmitter<ContratoSelecionarFormaPagamentoOutput>();
  @Output() aoAplicarCupom = new EventEmitter<void>();
  @Output() aoDesaplicarCupom = new EventEmitter<void>();

  private subscription$: Subscription;

  public readonly diasPagamento = Object.values(DiaPagamento);
  public formaPagamentoAverbacaoMaiorParcelamento: TabelaPrecoParcelaListaDto;
  public formaPagamentoAverbacaoAVista: TabelaPrecoParcelaListaDto;
  public rotaAnterior: string;
  // variaveis criadas para salvar os valores do form após aplicar/desaplicar cupom
  // para que não sejam perdidos ao desaplicar o cupom e o form ser resetado
  private parcelaSelecionada: TabelaPrecoParcela;
  private diaEntradaSelecionadao: DiaPagamento;
  private diaVencimentoSelecionado: DiaPagamento;

  constructor(
    private readonly cupomService: CupomDescontoService,
    private readonly fb: FormBuilder,
    private readonly loadingService: LoadingService,
    private readonly location: Location,
    private readonly modalCtrl: ModalController,
    private readonly toastService: ToastService,
  ) {
    super();
    this.form = this.fb.group({
      diaEntrada: [null, Validators.required],
      diaVencimento: [null, Validators.required],
      totalParcelas: [null, Validators.required],
      meioPagamento: [PaymentType.TICKET, Validators.required],
    });
  }

  ngOnInit(): void {
    this.configurarRotaAnterior();

    this.subscription$ = this.form.get('totalParcelas').valueChanges
      .pipe(
        tap(() => this.configurarValidacaoVencimentoEEntrada())
      )
      .subscribe();
  }

  ionViewWillEnter(): void {
    this.preencherForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { cadastro } = changes;
    if (cadastro && cadastro.currentValue) {
      this.preencherForm();
    }

    this.configurarRotaAnterior();
    this.configurarValidacaoVencimentoEEntrada()
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  public get descontoNaEntrada(): boolean {
    return this.cadastro?.cupomDescontoTipo === CupomDescontoTipoDesconto.ENTRADA;
  }

  public get descontoNoServico(): boolean {
    return this.cadastro?.cupomDescontoTipo === CupomDescontoTipoDesconto.SERVICO;
  }

  public get valorTotal(): number {
    return this.pagamentoSelecionado?.valorTotal;
  }

  public get valorDesconto(): number {
    return this.pagamentoSelecionado?.valorDesconto;
  }

  public get pagamentoAVista(): boolean {
    return this.pagamentoSelecionado?.parcela === TabelaPrecoParcela.PARCELA_0;
  }

  public get valorEntrada(): number {
    return this.pagamentoSelecionado?.valorAto;
  }

  public get opcoesParcelamento(): TabelaPrecoParcelaListaDto[] {
    return this.tabelaPrecoSelecionada?.parcelas || [];
  }

  public get pagamentoSelecionado(): TabelaPrecoParcelaListaDto {
    const pagamentoSelecionado = this.form?.get('totalParcelas')?.value;
    return this.tabelaPrecoSelecionada?.parcelas?.find(parcela => parcela.parcela === pagamentoSelecionado);
  }

  public get tabelaPrecoSelecionada(): Partial<TabelaPrecoDetalhesDto> {
    if (!this.tabelaPreco) {
      return null;
    }

    const possuiAverbacao = this.cadastro?.averbacaoSelecionada ?? this.cadastro.averbacao;
    const tabelaPrecoSelecionada = this.tabelaPreco
      .find((item) => item.modalidade === this.cadastro.reurbModalidade && (possuiAverbacao ? item.averbacao : !item.averbacao));
    return tabelaPrecoSelecionada ? tabelaPrecoSelecionada : this.tabelaPreco[0];
  }

  private configurarRotaAnterior(): void {
    const urlAtual = this.location.path();
    const urlNovoCadastro = urlAtual.includes('novo-cadastro');
    if (this.possuiPagamento) {
      this.rotaAnterior = '../selecionar-averbacao';
      return;
    }

    this.rotaAnterior = urlNovoCadastro ? '../../cadastrar-dados-proprietario' : '../../';
  }

  private configurarValidacaoVencimentoEEntrada(): void {
    const dataVencimentoControl = this.form.get('diaVencimento');
    const diaEntradaControl = this.form.get('diaEntrada');
    if (this.pagamentoAVista) {
      dataVencimentoControl.clearValidators();
      dataVencimentoControl.setValue(null);
      diaEntradaControl.clearValidators();
      diaEntradaControl.setValue(null);
    } else {
      dataVencimentoControl.setValidators(Validators.required);
      diaEntradaControl.setValidators(Validators.required);
    }

    dataVencimentoControl.updateValueAndValidity();
    diaEntradaControl.updateValueAndValidity();
  }


  private preencherForm(): void {
    this.form.patchValue({
      diaEntrada: this.cadastro.diaEntradaSelecionado ?? this.diaEntradaSelecionadao,
      totalParcelas: this.cadastro.totalParcelasSelecionada?.toString() ?? this.parcelaSelecionada,
      diaVencimento: this.cadastro.diaVencimentoSelecionado ?? this.diaVencimentoSelecionado
    });
  }

  public async abrirModalAdicionarCupom(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalAdicionarCupomDescontoComponent,
      componentProps: {
        cadastroId: this.cadastro.id,
        quantidadeParcelas: this.pagamentoSelecionado?.quantidadeParcela,
      },
      cssClass: 'modal-adicionar-cupom',
    });

    await modal.present();
    const { data } = await modal.onDidDismiss<{ cupomAplicado: boolean, resetarParcelas: boolean }>();
    if (data.cupomAplicado) {
      this.preencherValoresSelecionados(data.resetarParcelas);
      this.aoAplicarCupom.emit();
      this.toastService.success('Cupom adicionado com sucesso.').toPromise();
    }
  }

  public async removerCupom(): Promise<void> {
    await this.loadingService.createLoader();
    this.cupomService.desaplicarCupom(this.cadastro.id)
      .pipe(
        tap(() => this.preencherValoresSelecionados()),
        tap(() => this.aoDesaplicarCupom.emit()),
        catchError(error => {
          this.toastService.error('Não foi possível desaplicar o cupom de desconto');
          throw error;
        }),
        finalize(() => this.loadingService.dismiss())
      )
      .subscribe();
  }

  private preencherValoresSelecionados(resetarParcelas = false): void {
    const { diaEntrada, totalParcelas, diaVencimento } = this.form.value;
    this.parcelaSelecionada = resetarParcelas ? null : totalParcelas;
    this.diaEntradaSelecionadao = diaEntrada;
    this.diaVencimentoSelecionado = diaVencimento;
  }

  public submit(): void {
    if (this.possuiPagamento) {
      this.form.markAllAsTouched();
      if (this.form.invalid) {
        this.toastService.warning('Selecione uma opção de pagamento para fechar o contrato').toPromise();
        return;
      }
    }

    this.aoSelecionarFormaPagamento.emit(this.form.value);
  }
}
