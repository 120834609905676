import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TabelaPrecoDetalhesDto } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, CadastroStateDto, LoadingService, ToastService } from '@usucampeao/ui-mobile';
import { catchError, filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProjetoQuery } from '../../../../core/states/projeto/projeto.query';
import { ProjetoService } from '../../../../core/states/projeto/projeto.service';
import { ProjetoStateDto } from '../../../../core/states/projeto/projeto.store';
import { CadastrosQuery } from '../../state/cadastros.query';
import { CadastroService } from '../../state/cadastros.service';

@Component({
  templateUrl: './contrato-selecionar-averbacao.page.html',
  styleUrls: ['./contrato-selecionar-averbacao.page.scss']
})
export class ContratoSelecionarAverbacaoPage extends BaseComponent implements OnInit {

  private cadastroId: string;
  public cadastro: CadastroStateDto;
  public projeto: Partial<ProjetoStateDto>;
  public tabelaPreco: TabelaPrecoDetalhesDto[];

  constructor(
    private cadastrosQuery: CadastrosQuery,
    private cadastrosService: CadastroService,
    private loadingService: LoadingService,
    private projetoQuery: ProjetoQuery,
    private projetoService: ProjetoService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap(params => this.cadastroId = params.cadastroId),
        tap(() => this.carregarCadastro()),
        switchMap(() => this.cadastrosQuery.selectEntity(this.cadastroId)),
        tap(cadastro => this.cadastro = cadastro),
        switchMap(cadastro => this.projetoQuery.selectEntity(cadastro.projetoFid)),
        tap(projeto => this.projeto = projeto),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe()
  }

  /**
   * Carrega os dados completos do cadastro e tabela de preço do projeto.
   * @param event - Evento de loader do ion-refresh. Se não for informado é utilizado o loader normal.
   */
  public async carregarCadastro(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.cadastrosService.buscarCadastro(this.cadastroId)
      .pipe(
        switchMap(cadastro => this.projetoService.buscarDadosSimplificados(cadastro.projetoFid)),
        filter(projeto => !projeto.semPagamento),
        switchMap(() => this.buscarFormasDePagamentoDoCadastro()),
        catchError(() => this.toastService.error('Erro ao buscar dados do contrato. Por favor tente novamente.')),
        finalize(async () => event ? event.target.complete() : await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public buscarFormasDePagamentoDoCadastro() {
    return this.cadastrosService.buscarFormasDePagamentoDisponiveisParaOCadastro(this.cadastro.id)
      .pipe(
        tap(tabelaPreco => this.tabelaPreco = tabelaPreco),
      );
  }

  public aoSelecionarAverbacao(averbacaoSelecionada: boolean): void {
    this.cadastrosService.alterarCadastroNoEstado(this.cadastro.id, { averbacaoSelecionada });
    this.router.navigate(['../selecionar-forma-pagamento'], { relativeTo: this.route });
  }
}
