<div class="mapa flex-column">
  <div class="mapa__searchbar" *ngIf="mostrarBusca">
    <ion-searchbar #buscaEnderecoInput id="busca-endereco-mapa" data-cy="nome" type="text" placeholder="Procure por localidade, endereço..."
      #searchbar>
    </ion-searchbar>
  </div>

  <div class="mapa__container" *ngIf="lat && lng">
    <usucampeao-mapa-google *ngIf="isGoogleApiCarregada" [lotes]="lotes"
      [loteSelecionadoId]="loteSelecionadoId" [lat]="lat" [lng]="lng" [zoomInicial]="mapa?.zoomInicial"
      [desabilitado]="desabilitado" [mostrarMarker]="mostrarMarker" (aoSelecionarLote)="selecionarLote($event)"></usucampeao-mapa-google>

    <usucampeao-mapa-mapbox *ngIf="!isGoogleMaps" [lotes]="lotes" [loteSelecionadoId]="loteSelecionadoId" [lat]="lat"
      [lng]="lng" [mapa]="mapa" [desabilitado]="desabilitado" [mostrarMarker]="mostrarMarker"
      (aoSelecionarLote)="selecionarLote($event)"></usucampeao-mapa-mapbox>

    <div class="mapa__modal" *ngIf="showModal && !desabilitado">
      <div class="mapa__modal__container">
        <ion-text class="font-text font-text--large ion-padding-top">
          <strong>Quadra {{loteSelecionado.quadra || ''}}, Lote {{ loteSelecionado.label || '' }}</strong>
        </ion-text>

        <div class="mapa__modal__actions">
          <ion-button class="ion-margin-top" expand="block" mode="md" (click)="confirmarSelecaoLote()">
            Continuar
          </ion-button>

          <ion-text>
            <p class="font-text ion-text-center ion-margin-top" (click)="mostrarModalAjudaSelecaoLote()">
              Solicitar ajuda
            </p>
          </ion-text>
        </div>
      </div>
    </div>
  </div>
</div>
