<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="carregarCadastro($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <usucampeao-contrato-selecionar-averbacao *ngIf="!!cadastro && !!tabelaPreco" [possuiAverbacao]="projeto?.averbacao ?? false"
    [cadastro]="cadastro" [tabelaPreco]="tabelaPreco" (aoSelecionarAverbacao)="aoSelecionarAverbacao($event)">
  </usucampeao-contrato-selecionar-averbacao>
</ion-content>
