<ion-content class="ion-padding">
  <ion-refresher slot="fixed" (ionRefresh)="carregarCadastros($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <section class="home">
    <usucampeao-header [title]="(nomeUsuario$ | async)" [showBackButton]="false"></usucampeao-header>

    <ng-container *ngIf="(cadastros$ | async).length">
      <div class="home__list usu-scroll home__item" *ngIf="(cadastrosSemContrato$ | async).length">
        <usucampeao-cadastro-card *ngFor="let cadastro of (cadastrosSemContrato$ | async)"
          [cadastro]="cadastro"></usucampeao-cadastro-card>
      </div>

      <div class="home__item" *ngIf="(cadastrosComPendencias$ | async).length">
        <ion-text>
          <h2 class="font-text font-text--medium font-text--bold ion-no-margin">Avisos</h2>
        </ion-text>
        <div class="home__list usu-scroll">
          <usucampeao-cadastro-card-aviso *ngFor="let cadastro of (cadastrosComPendencias$ | async)"
            [cadastro]="cadastro"></usucampeao-cadastro-card-aviso>
        </div>
      </div>

      <div class="home__item" *ngIf="pagamentos.length">
        <ion-text>
          <h2 class="font-text font-text--medium font-text--bold">
            Boletos disponíveis
          </h2>
        </ion-text>

        <div class="home__list home__list--small usu-scroll">
          <usucampeao-pagamento-card *ngFor="let pagamento of pagamentos"
            [pagamento]="pagamento"></usucampeao-pagamento-card>
        </div>
      </div>

      <div class="home__item">
        <ion-text>
          <h2 class="font-text font-text--medium font-text--bold">
            Meus cadastros
          </h2>
        </ion-text>

        <div class="home__grid">
          <usucampeao-menu-item-v2 icone="home" titulo="Meus imóveis" (click)="irParaMeusImoveis()">
          </usucampeao-menu-item-v2>
          <usucampeao-menu-item-v2 icone="barcode" titulo="Boletos" (click)="irParaMeusBoletos()"></usucampeao-menu-item-v2>
        </div>

        <ion-button *ngIf="!(cadastrosSemContrato$ | async).length" mode="md" (click)="iniciarNovoCadastro()">
          Cadastrar outro imóvel
        </ion-button>
      </div>
    </ng-container>

    <ng-container *ngIf="!(cadastros$ | async).length">
      <img src="assets/images/home/home_vazia.svg" class="home__imagem">
      <ion-text class="ion-text-center">
        <p class="font-text">
          Nenhum imóvel cadastrado.
          <br>
          <br>
          Bem vindo ao aplicativo do morador, da UsuCampeão. Cadastre o seu imóvel para dar o primeiro passo na
          regularização da sua propriedade.
        </p>
      </ion-text>

      <ion-button class="home__margin-top" mode="md" (click)="iniciarNovoCadastro()">Cadastrar meu imóvel</ion-button>
    </ng-container>
  </section>
</ion-content>
