import { Endereco } from '@usucampeao/interfaces';
import { IsArray, IsNumber } from 'class-validator';
import { GatewayPagamento, ProjetoComissaoTipo, ProjetoTipo } from '..';
import { ProjetoEndereco } from './projeto-endereco.model';
import { ProjetoModalidadeType } from './projeto-modalidade.enum';
import { ProjetoStatus } from './projeto-status.enum';
import { ProjetoVendaStatus } from './projeto-venda-status.enum';

export class ProjetoDto {
  id: string;
  nome: string;
  descricao?: string;
  modalidade?: ProjetoModalidadeType;
  averbacao?: boolean;
  imagem: string;
  localizacao: Endereco;
  tipo: ProjetoTipo;
  cadastroDataInicio: Date;
  cadastroDataFim: Date;
  preAnaliseDataInicio: Date;
  preAnaliseDataFim: Date;
  escritorioDataInicio: Date;
  escritorioDataFim: Date;
  escritorioLocal: ProjetoEndereco;
  visitaDataInicio: Date;
  visitaDataFim: Date;
  prefeituraDataInicio: Date;
  prefeituraDataFim: Date;
  cartorioDataInicio: Date;
  cartorioDataFim: Date;
  matriculaDataInicio: Date;
  matriculaDataFim: Date;
  assembleiaLocal: ProjetoEndereco;
  assembleiaData: Date;
  vendaStatus: ProjetoVendaStatus;
  loteQuantidade: number;
  quadraQuantidade: number;
  dataCriacao: Date;
  usuarioCriacaoId: string;
  dataAtualizacao: Date;
  usuarioAtualizacaoId: string;
  status: ProjetoStatus;
  semPagamento: boolean;
  nucleoId: number;
  omieProjetoId: number;
  comissaoTipo: ProjetoComissaoTipo;
  comissaoReurb: number;
  comissaoReurbAverbacao: number;
  comissaoAverbacao: number;
  favorito: boolean;
  gatewayPagamento: GatewayPagamento;
  publicadoDiarioOficialData?: Date;
  cartorioRegistroData?: Date;
  tituloEntregaData?: Date;
  tituloEntregaLocalizacao?: ProjetoEndereco;
}

export class ProjetoQuadraEditarDto {
  @IsArray()
  @IsNumber({}, { each: true })
  quadrasIds: number[];
}
