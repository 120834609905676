import * as numero from 'numero-por-extenso';

export const numeroExtenso = (value: string | number, tipo?: NumeroExtensoTipo) => {
  return numero.porExtenso(value, tipo);
}

export enum NumeroExtensoTipo {
  MONETARIO = numero.estilo.monetario,
  PORCENTAGEM = numero.estilo.porcentagem
}
