import {
  CadastroContratoAssinaturaTipoEnvioLink,
  CadastroContratoTipoAssinatura,
  calcularStatusContrato,
  ContratoStatus
} from '@usucampeao/interfaces';
import { Expose, plainToInstance } from 'class-transformer';
import { ReurbModalidade } from '../../enums';
import { CupomDescontoTipoDesconto } from '../cupom-desconto';
import { MeioPagamento, PagamentoListagemDto } from '../pagamento';
import { DiaPagamento } from '../pagamento/dia-pagamento.enum';
import { ProjetoDto, ProjetoTipo } from '../projeto';
import { PropertyDto } from '../property';
import { ProprietariosCadastroDto } from '../proprietario-cadastro';
import { CadastroStatusVendaType } from './cadastro-status-venda.enum';
import { ContratoVersao } from './contrato-versao.enum';
import { RegistrationStatus } from './registration-status.enum';
import { RegistrationDto } from './registration.dto';

type CadastroContratoProps = {
  proprietarios: ProprietariosCadastroDto[];
  imovel: PropertyDto;
  projeto: ProjetoDto;
  pagamentos?: PagamentoListagemDto[];
  cadastro?: RegistrationDto
}

export class CadastroContratoDto {
  @Expose()
  id: string;

  @Expose()
  status: RegistrationStatus;

  @Expose()
  statusVenda: CadastroStatusVendaType;

  @Expose()
  projetoFid: string;

  @Expose()
  projetoTipo: ProjetoTipo;

  @Expose()
  projeto: string;

  @Expose()
  projetoMunicipio: string;

  @Expose()
  reurbModalidade?: ReurbModalidade;

  @Expose()
  blockId: number;

  @Expose()
  lotId: number;

  @Expose()
  quadra: string;

  @Expose()
  lote: string;

  @Expose()
  valorContrato: number;

  @Expose()
  valorReurb: number;

  @Expose()
  totalParcelas: number;

  @Expose()
  valorParcela: number;

  @Expose()
  juros: number;

  @Expose()
  jurosMes: number;

  @Expose()
  multa: number;

  @Expose()
  entradaVencimento: Date;

  @Expose()
  valorServico: number;

  @Expose()
  descontoPorcentagem: number;

  @Expose()
  valorDesconto: number;

  @Expose()
  meioPagamento: MeioPagamento;

  @Expose()
  valorEntrada: number;

  @Expose()
  diaVencimento: DiaPagamento;

  @Expose()
  diaEntrada: DiaPagamento;

  @Expose()
  proprietarios: ProprietariosCadastroDto[];

  @Expose()
  imovel: PropertyDto;

  @Expose()
  contractDate: Date;

  @Expose()
  contratoDataAssinatura: Date;

  @Expose()
  contratoTipoAssinatura: CadastroContratoTipoAssinatura;

  @Expose()
  contratoDataExpiracao: Date;

  @Expose()
  averbacao: boolean;

  @Expose()
  valorAverbacao?: number;

  @Expose()
  contratoVersao: ContratoVersao;

  @Expose()
  pagamentos: PagamentoListagemDto[];

  @Expose()
  contratoStatus: ContratoStatus;

  @Expose()
  cupomDescontoCodigo: string;

  @Expose()
  cupomDescontoLimite: number;

  @Expose()
  cupomDescontoTipo: CupomDescontoTipoDesconto;

  @Expose()
  semPagamento: boolean;

  @Expose()
  contratoLinkTipoEnvio?: CadastroContratoAssinaturaTipoEnvioLink;

  @Expose()
  contratoLinkFalhaEnvio?: boolean;

  /**
   * Cria uma nova instância de CadastroContratoDto a partir de dtos
   * @param proprietarios - Proprietários do contrato
   * @param imovel - Imóvel do contrato
   * @param projeto - Projeto do projeto
   * @param cadastro - DTO de Cadastro
   * @returns nova instância de CadastroContratoDto
   */
  static build(props?: CadastroContratoProps): CadastroContratoDto {
    const { proprietarios, imovel, projeto, pagamentos, cadastro } = props;
    const cadastroContratoDto = plainToInstance(CadastroContratoDto, cadastro, { exposeUnsetFields: false, excludeExtraneousValues: true });
    cadastroContratoDto.proprietarios = proprietarios;
    cadastroContratoDto.imovel = imovel;
    cadastroContratoDto.projetoMunicipio = projeto.localizacao?.cidade;
    cadastroContratoDto.projeto = projeto.nome;
    cadastroContratoDto.semPagamento = projeto.semPagamento;
    cadastroContratoDto.pagamentos = pagamentos || [];
    if (!cadastroContratoDto.contratoVersao) {
      cadastroContratoDto.contratoVersao = ContratoVersao.V1;
    }
    cadastroContratoDto.contratoStatus = calcularStatusContrato(cadastroContratoDto.contratoDataExpiracao, !!cadastroContratoDto.contratoDataAssinatura);
    return cadastroContratoDto;
  }
}
