<ion-card class="atendimento-card ion-no-margin ion-margin-bottom">
  <img *ngIf="showImage" class="img-facade" [lazyLoad]="fotoFachadaImovel" [defaultImage]="loadingImage"
    [errorImage]="defaultImage" />

  <ion-card-header *ngIf="!statusPadrao">
    <usucampeao-atendimento-status-badge [status]="cadastro?.status"></usucampeao-atendimento-status-badge>
  </ion-card-header>

  <ion-card-content>
    <ion-text class="title title--variant">
      {{ cadastro?.projetoNome ||''}}
    </ion-text>
    <ng-container *ngIf="cadastro?.address; let address">
      <ion-text class="font-text font-text--small">
        {{ address.street }}, {{address.number}} {{address.complement}}
      </ion-text>
      <ion-text class="description-text description-text--small description-text--light">
        {{ formatLocation(address) }}
      </ion-text>
    </ng-container>

    <ng-container *ngIf="!cadastro?.address">
      <ion-text class="font-text font-text--small">
        Endereço não cadastrado
      </ion-text>
      <ion-text class="description-text description-text--small description-text--light">
        {{ formatLocation() }}
      </ion-text>
    </ng-container>

    <ng-container *ngIf="showOwnerProps">
      <ion-text class="title title--variant ion-margin-top">
        {{ cadastro?.proprietarioNome }}
      </ion-text>

      <ion-text class="font-text font-text--small">
        {{ cadastro?.proprietarioCpf | mask: '000.000.000-00' }}
      </ion-text>
    </ng-container>

    <div class="atendimento-card__status-padrao" *ngIf="statusPadrao">
      <usucampeao-cadastro-status [status]="cadastro?.status" [alteracoes]="alteracoes" [statusSimplificado]="true"></usucampeao-cadastro-status>
    </div>
  </ion-card-content>

  <ion-footer mode="md">
    <ion-button fill="clear" expand="full" (click)="navigateToInformationForm()">
      Ver
    </ion-button>
  </ion-footer>
</ion-card>
