import { IsEnum, IsNotEmpty, IsNumberString, IsOptional, IsString, Length } from 'class-validator';
import { GatewayPagamento } from '../pagamento';
import { ProjetoModalidadeType } from './projeto-modalidade.enum';

export class ProjetoEditarFormDataDto {
  @IsString()
  @IsNotEmpty()
  nome: string;

  @IsString()
  @IsOptional()
  descricao?: string;

  @IsNumberString()
  @Length(8, 8)
  cep: string;

  @IsString()
  @IsNotEmpty()
  rua: string;

  @IsNumberString()
  @IsOptional()
  numero?: string;

  @IsString()
  @IsOptional()
  complemento?: string;

  @IsString()
  @IsOptional()
  bairro?: string;

  @IsString()
  @IsOptional()
  modalidade?: ProjetoModalidadeType;

  @IsString()
  @IsOptional()
  averbacao?: string;

  @IsString()
  @IsOptional()
  omieProjetoId?: string;

  @IsEnum(GatewayPagamento)
  @IsNotEmpty()
  gatewayPagamento: GatewayPagamento;

  constructor(projetoEditarFormData?: ProjetoEditarFormDataDto) {
    if (projetoEditarFormData) {
      Object.keys(projetoEditarFormData).forEach(key => this[key] = projetoEditarFormData[key]);
    }
  }
}
