/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertySituation, TabelaPrecoDetalhesDto } from '@usucampeao/lib-reurb-simplificado';
import { CadastroStateDto, ImovelStateDto, ProprietarioStateDto } from '../../../domain';

@Component({
  selector: 'usucampeao-contrato-confirmar-informacoes',
  templateUrl: './contrato-confirmar-informacoes.component.html',
  styleUrls: ['./contrato-confirmar-informacoes.component.scss']
})
export class ContratoConfirmarInformacoesComponent {
  @Input() cadastro: CadastroStateDto;
  @Input() imovel: ImovelStateDto;
  @Input() proprietario: ProprietarioStateDto;
  @Input() possuiPagamento: boolean;
  @Input() tabelaPreco: Partial<TabelaPrecoDetalhesDto>[];

  @Output() aoSolicitarPreviaContrato = new EventEmitter<void>();
  @Output() aoSolicitarAssinaturaContrato = new EventEmitter<string>();

  public form: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {
    this.form = this.fb.group({
      observacoes: [null]
    });
  }

  public get situacaoImovel(): typeof PropertySituation {
    return PropertySituation;
  }

  public async visualizarPreviaContrato(): Promise<void> {
    this.aoSolicitarPreviaContrato.emit();
  }

  public solicitarAssinaturaContrato(): void {
    const { observacoes } = this.form.value;
    this.aoSolicitarAssinaturaContrato.emit(observacoes);
  }

  public navegarPara(url: string): void {
    this.router.navigate([url], { relativeTo: this.route });
  }
}
