import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { DispositivoDto } from '@usucampeao/lib-reurb-simplificado';
import { ToastService } from '@usucampeao/ui-mobile';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import * as packageJson from '../../../../../../package.json';
import { environment } from '../../../environments/environment';
import { NotificationsService } from '../../pages/notifications/state/notifications.service';

const LOCAL_STORAGE_DISPOSITIVO_TOKEN = 'dispositivo_token';
const LOCAL_STORAGE_VERSAO_APP = 'versao_app';
@Injectable({ providedIn: 'root' })
export class PushNotificationService {
  private pushNotificationInicializado = false;

  constructor(
    private readonly angularFireMessaging: AngularFireMessaging,
    private readonly http: HttpClient,
    private readonly notificacaoService: NotificationsService,
    private readonly router: Router,
    private readonly toastService: ToastService,
  ) {
    this.angularFireMessaging.usePublicVapidKey(environment.publicVapidKey);
  }

  public inicializar(): void {
    if (this.pushNotificationInicializado) {
      return;
    }
    this.pushNotificationInicializado = true;
    console.log('Inicializando serviço de notificações');
    this.inicializarOuvinteNotificacoes();
    this.buscarTokenDispositivo();
  }

  /**
   * Inicializa o ouvinte de notificações.
   *
   * Este método é responsável por configurar o ouvinte de mensagens de notificação.
   * Quando uma nova mensagem de notificação é recebida, o método verifica se a mensagem
   * possui um corpo de notificação e redireciona o usuário para a URL especificada na mensagem.
   * Em seguida, o método realiza uma busca por notificações não lidas.
   *
   * @returns Nenhum valor de retorno.
   */
  private inicializarOuvinteNotificacoes(): void {
    this.angularFireMessaging.messages
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tap((message: any) => {
          console.log('Notificação recebida', message);
          if (message.notification && message.notification.body) {
            const notificacaoId = message.data.notificacaoId;
            const url = notificacaoId ? `notificacoes/${notificacaoId}` : 'home';

            navigator.serviceWorker.addEventListener('notificationclick', (event: any) => {
              console.log('Clicou na notificação');
              event.notification.close();
              event.waitUntil(
                this.router.navigateByUrl(url)
              );
            });
          }
        }),
        switchMap(() => this.notificacaoService.buscarNotificacaoNaoLidas()),
      )
      .subscribe();
  }

  /**
   * Busca o token do dispositivo.
   */
  private buscarTokenDispositivo(): void {
    this.angularFireMessaging.requestToken
      .pipe(
        tap(token => this.salvarTokenDispositivo(token)),
        catchError(error => {
          Bugsnag.notify(error, event => {
            event.addMetadata('error tracing', {
              message: 'Erro ao buscar token do dispositivo',
            });
            event.addMetadata('error response', error.response);
            event.addMetadata('error payload', error.payload);
          });
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * Salva o token do dispositivo no localStorage e no backend.
   * Caso o token já esteja salvo, não faz nada.
   *
   * @param token - Token do dispositivo
   * @returns
   */
  private salvarTokenDispositivo(token: string): void {
    const versaoApp = localStorage.getItem(LOCAL_STORAGE_VERSAO_APP);
    if (versaoApp === packageJson.version) {
      return;
    }

    this.adicionarDispositivoAoUsuario(token);
  }

  /**
   * Adiciona um dispositivo ao usuário.
   *
   * @param token - Token do dispositivo
   */
  private adicionarDispositivoAoUsuario(token: string): void {
    this.http.post<DispositivoDto>('/users/me/dispositivos', { token })
      .pipe(
        tap(() => localStorage.setItem(LOCAL_STORAGE_DISPOSITIVO_TOKEN, token)),
        tap(() => localStorage.setItem(LOCAL_STORAGE_VERSAO_APP, packageJson.version)),
        catchError(error => {
          this.toastService.error('Não foi possível adicionar o dispositivo para receber notificações.');
          Bugsnag.notify(error, event => {
            event.addMetadata('error tracing', {
              message: 'Erro ao adicionar dispositivo ao usuário',
            });
            event.addMetadata('error response', error.response);
            event.addMetadata('error payload', error.payload);
          });

          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * Remove o dispositivo do usuário.
   */
  public removerDispositivo(): Observable<void> {
    const token = localStorage.getItem(LOCAL_STORAGE_DISPOSITIVO_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_DISPOSITIVO_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_VERSAO_APP);
    if (!token) {
      of(null);
    }

    return this.http.delete<void>(`/users/me/dispositivos/${token}`)
      .pipe(
        catchError(error => {
          Bugsnag.notify(error, (event) => {
            event.addMetadata('error response', error.response);
            event.addMetadata('error payload', error.payload);
          });
          return of(null);
        })
      );
  }
}
