import { UF } from '@usucampeao/interfaces';
import { IsEnum, IsIn, IsNotEmpty, IsOptional, IsString, ValidateIf } from 'class-validator';
import { GatewayPagamento } from '../pagamento';
import { ProjetoEditarFormDataDto } from './projeto-editar-form-data.dto';
import { ProjetoTipo } from './projeto-tipo.enum';

export class ProjetoCriarFormDataDto extends ProjetoEditarFormDataDto {
  @IsString()
  @IsNotEmpty()
  tipo: ProjetoTipo

  @IsString()
  @IsNotEmpty()
  nucleoId: string;

  @IsIn(['true', 'false'])
  @IsString()
  @IsOptional()
  semPagamento: string;

  @IsString()
  @IsNotEmpty()
  cidade: string;

  @IsString()
  @IsNotEmpty()
  estado: UF;

  @IsIn(['true', 'false'])
  @IsString()
  @IsOptional()
  importarQuadras: string;

  @IsEnum(GatewayPagamento)
  @IsNotEmpty()
  @ValidateIf((object) => object.semPagamento === 'false')
  gatewayPagamento: GatewayPagamento;
}
