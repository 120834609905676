import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertController, ModalController, ViewWillLeave } from '@ionic/angular';
import { LoteListarMapaDto, MapaDto } from '@usucampeao/lib-reurb-simplificado';
import { AbstractForm } from '@usucampeao/utils-frontend';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LocalizacaoLatLong, LocalizacaoService } from '../../../services';
import { EnderecoService } from '../../../services/endereco.service';
import { LoadingService } from '../../../shared';
import { LoteSelecionarTutorialComponent } from './lote-selecionar-tutorial/lote-selecionar-tutorial.component';

@Component({
  selector: 'usucampeao-lote-selecionar',
  templateUrl: 'lote-selecionar.page.html',
  styleUrls: ['lote-selecionar.page.scss'],
})
export class LoteSelecionarPage extends AbstractForm implements OnInit, ViewWillLeave, OnDestroy {
  @Input() set cadastroId(cadastroId: string) {
    if (!cadastroId) {
      this.rotaAnterior = '../';
    } else {
      this.rotaAnterior = null;
    }
  }
  @Input() mapa: MapaDto;
  @Input() lotes: LoteListarMapaDto[];
  @Output() aoSelecionarLote = new EventEmitter<LoteListarMapaDto>();

  private ngUnsubscribe$ = new Subject<void>();
  public localizacaoEncontrada: LocalizacaoLatLong | null;
  public mapaVisivel = false;
  public rotaAnterior: string;

  constructor(
    private readonly alertCtrl: AlertController,
    private readonly enderecoService: EnderecoService,
    private readonly formBuilder: FormBuilder,
    private readonly localizacaoService: LocalizacaoService,
    private readonly loadingService: LoadingService,
    private readonly modalCtrl: ModalController,
  ) {
    super();
    this.form = this.formBuilder.group({
      cep: [null, [Validators.required, Validators.minLength(9)]],
      logradouro: [null, Validators.required],
      numero: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.localizacaoEncontrada = this.localizacaoService.buscarLocalizacaoSelecionadaPeloUsuario();
    this.preencherFormComLocalizacaoSelecionada();
    this.inscreverMudancasCep();
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe$.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.complete();
  }

  private inscreverMudancasCep(): void {
    this.form.get('cep').valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter(value => value?.length === 9),
        switchMap(cep => this.enderecoService.buscarCep(cep)),
        tap(endereco => {
          this.form.patchValue({
            logradouro: endereco.street,
            numero: null,
          })
        }
        ),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  /**
   * Busca a localização do usuário.
   */
  public async buscarLocalizacaoUsuario() {
    await this.loadingService.createLoader();
    navigator.geolocation.getCurrentPosition(async (resp) => {
      this.localizacaoEncontrada = await this.localizacaoService.buscarLocalizacaoPorLatLong(resp.coords.latitude, resp.coords.longitude);
      this.preencherFormComLocalizacaoSelecionada();
      this.loadingService.dismiss();
    }, async () => {
      this.loadingService.dismiss();
      const alert = await this.alertCtrl.create({
        header: 'Atenção',
        message: 'Não foi possível obter sua localização. Conceda a permissão de acesso à sua localização e tente novamente.',
        backdropDismiss: true,
        buttons: [
          {
            text: 'Ok',
          },
        ],
      });
      await alert.present();
    });
  }

  public submitForm(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.buscarLocalizacaoPeloEndereco();
  }

  private async buscarLocalizacaoPeloEndereco(): Promise<void> {
    await this.loadingService.createLoader();
    const endereco = this.form.value;
    try {
      this.localizacaoEncontrada = await this.localizacaoService.buscarLocalizacaoPeloEndereco(endereco);
      await this.loadingService.dismiss();
      const { lat, long } = this.localizacaoEncontrada;
      if (
        lat > this.mapa.latitudeMaxima ||
        lat < this.mapa.latitudeMinima ||
        long > this.mapa.longitudeMaxima ||
        long < this.mapa.longitudeMinima
      ) {
        const alert = await this.alertCtrl.create({
          header: 'Atenção',
          message: 'A localização informada não está dentro deste bairro. Verifique o endereço e tente novamente, ou tente escolher outro bairro.',
          backdropDismiss: true,
          buttons: [
            {
              text: 'Ok',
            },
          ],
        });
        await alert.present();
        return;
      }
      this.mapaVisivel = true;
    } catch (error) {
      await this.loadingService.dismiss();
    }
  }

  private preencherFormComLocalizacaoSelecionada(): void {
    this.form.patchValue({
      cep: this.localizacaoEncontrada?.cep || null,
      logradouro: this.localizacaoEncontrada?.logradouro || null,
      numero: this.localizacaoEncontrada?.numero || null,
    });
  }

  public alterarLocalizacao(): void {
    this.mapaVisivel = false;
  }

  public async abrirModalTutorialSelecaoLote(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: LoteSelecionarTutorialComponent,
    });

    await modal.present();
  }

  public selecionarLote(lote: LoteListarMapaDto): void {
    this.aoSelecionarLote.emit(lote);
  }
}
