import { ArrayMinSize, IsArray, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsPositive, Max, Min } from 'class-validator';
import { ReurbModalidade } from '../../enums/reurb-modalidade.enum';
import { TabelaPrecoParcela } from './tabela-preco-parcela.enum';

export const PARCELAS_SEM_JUROS_PADRAO = 12;
export const JUROS_MES_PADRAO = 1.5;

export class TabelaPrecoDto {
  @IsPositive()
  @IsNotEmpty()
  valorBase: number;

  @IsPositive()
  @IsNotEmpty()
  valorEfetivo: number;

  @IsPositive()
  @IsNotEmpty()
  valorAto: number;

  @IsPositive()
  @IsOptional()
  valorAverbacao?: number;

  @IsArray()
  @ArrayMinSize(1)
  @IsEnum(TabelaPrecoParcela, { each: true })
  @IsNotEmpty()
  parcelamentos: TabelaPrecoParcela[];

  @Min(0)
  @Max(20)
  @IsNumber()
  @IsNotEmpty()
  desconto: number;

  @IsPositive()
  @IsNotEmpty()
  jurosMes: number;

  @IsPositive()
  @IsNotEmpty()
  parcelasSemJuros: number;

  @Min(0)
  @Max(20)
  @IsNumber()
  @IsNotEmpty()
  multa: number;

  @Min(0)
  @Max(20)
  @IsNumber()
  @IsNotEmpty()
  juros: number;

  @IsEnum(ReurbModalidade)
  @IsOptional()
  modalidade?: ReurbModalidade;
}
