/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  TabelaPrecoDetalhesDto,
  TabelaPrecoParcela,
  TabelaPrecoParcelaListaDto
} from '@usucampeao/lib-reurb-simplificado';
import { AbstractForm } from '@usucampeao/utils-frontend';
import { CadastroStateDto } from '../../../domain';

@Component({
  selector: 'usucampeao-contrato-selecionar-averbacao',
  templateUrl: './contrato-selecionar-averbacao.component.html',
  styleUrls: ['./contrato-selecionar-averbacao.component.scss']
})
export class ContratoSelecionarAverbacaoComponent extends AbstractForm implements OnInit {
  @Input() cadastro: CadastroStateDto;
  @Input() possuiAverbacao: boolean;
  @Input() set tabelaPreco(tabelaPreco: Partial<TabelaPrecoDetalhesDto>[]) {
    const formasPagamentoComAverbacao = tabelaPreco?.find(item => item.averbacao && item.modalidade === this.cadastro.reurbModalidade);
    this.formaPagamentoAverbacaoMaiorParcelamento = formasPagamentoComAverbacao?.parcelas?.reduce((prev, current) => {
      return +prev.parcela > +current.parcela ? prev : current;
    });
    this.formaPagamentoAverbacaoAVista = formasPagamentoComAverbacao?.parcelas?.find(item => item.parcela === TabelaPrecoParcela.PARCELA_0);
  }
  @Output() aoSelecionarAverbacao = new EventEmitter<boolean>();

  public formaPagamentoAverbacaoMaiorParcelamento: TabelaPrecoParcelaListaDto;
  public formaPagamentoAverbacaoAVista: TabelaPrecoParcelaListaDto;
  public rotaAnterior: string;

  constructor(
    private fb: FormBuilder,
    private location: Location,
  ) {
    super();
    this.form = this.fb.group({
      averbacao: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.form.setValue({ averbacao: this.cadastro?.averbacaoSelecionada ?? this.possuiAverbacao });
    this.configurarRotaAnterior();
  }

  private configurarRotaAnterior(): void {
    const urlAtual = this.location.path();
    this.rotaAnterior = urlAtual.includes('novo-cadastro') ? '../../cadastrar-dados-proprietario' : '../../';
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const { averbacao } = this.form.value;
    this.aoSelecionarAverbacao.emit(averbacao);
  }
}
