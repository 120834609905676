import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { MaritalStatus, StatusPreenchimento } from '@usucampeao/lib-reurb-simplificado';
import { ProprietarioFichaDto } from '@usucampeao/ui-mobile';

@Component({
  selector: 'usucampeao-proprietario-ficha',
  templateUrl: './proprietario-ficha.component.html',
  styleUrls: ['./proprietario-ficha.component.scss']
})
export class ProprietarioFichaComponent {
  @Input() titulo: string;
  @Input() set proprietario(proprietario: ProprietarioFichaDto) {
    this.proprietarioSelecionado = proprietario;
    this.mostrarBotaoAdicionarConjuge = this.proprietarioSelecionado?.main &&
      this.proprietarioSelecionado?.maritalStatus === MaritalStatus.MARRIED
      && !this.proprietarioSelecionado?.spouseId;
    this.mostrarFlagAlteracoesNecessarias = Object.keys(proprietario?.fillInStatus)
      .some(key => proprietario?.fillInStatus[key] === StatusPreenchimento.ALTERACAO_NECESSARIA);
  }

  @Output() aoAdicionarConjuge = new EventEmitter<string>();
  @Output() aoRemoverConjuge = new EventEmitter<string>();

  public proprietarioSelecionado: ProprietarioFichaDto;
  public mostrarBotaoAdicionarConjuge: boolean;
  public mostrarFlagAlteracoesNecessarias: boolean;

  constructor(
    private alertCtrl: AlertController,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  public editarProprietario(): void {
    this.router.navigate([this.proprietarioSelecionado.id, 'editar'], { relativeTo: this.route });
  }

  public adicionarConjuge(): void {
    this.aoAdicionarConjuge.emit(this.proprietarioSelecionado.id);
  }

  public async mostrarAlertaRemocaoConjuge(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Atenção!',
      message: `
        <p>Tem certeza que deseja remover o cônjuge?</p>
        <p>Todos os dados cadastrados serão perdidos.</p>
        <p><b>Esta ação não pode ser desfeita.</b></p>
      `,
      buttons: [
        {
          text: 'Cancelar',
        }, {
          text: 'Confirmar',
          handler: () => {
            this.aoRemoverConjuge.emit(this.proprietarioSelecionado.spouseId);
          }
        }
      ]
    });

    await alert.present();
  }
}
