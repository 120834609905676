<section class="contrato">
  <usucampeao-header title="Enviar cadastro"></usucampeao-header>

  <ng-container *ngIf="!possuiPagamento">
    <ion-text>
      <h1 class="title">Serviço gratuito</h1>
      <p class="font-text font-text--medium ion-no-margin">
        O morador está adquirindo um serviço gratuito.
      </p>
      <p class="font-text font-text--medium ion-no-margin">
        Por favor, complete a inscrição assinando o termo de adesão.
      </p>
      <p class="font-text font-text--medium ion-margin-top">
        Outros serviços complementares opcionais estarão disponíveis para aquisição no futuro mediante pagamento.
      </p>
    </ion-text>

    <div class="contrato__container-valor">
      <ion-text>
        <span class="font-text">Valor total</span>
      </ion-text>
      <ion-text>
        <span class="title">Gratuito</span>
      </ion-text>
    </div>
  </ng-container>

  <ng-container *ngIf="possuiPagamento">
  <ion-text>
    <p class="font-text ion-margin-vertical">
      O cadastro preenchido para o imóvel identificado abaixo será enviado para pré-análise por nossa equipe técnica.
    </p>
  </ion-text>

  <div class="contrato__cadastro-resumo ion-padding">
    <ion-text>
      <h1 class="title">{{ projetoNome }}</h1>
    </ion-text>
    <div class="contrato__flex">
      <ion-icon name="map-outline" color="medium" class="ion-margin-end"></ion-icon>
      <ion-text color="medium">
        <p class="font-text ion-no-margin">Quadra {{ cadastro.quadra }} Lote {{ cadastro.lote }}</p>
      </ion-text>
    </div>
    <div class="contrato__flex">
      <ion-icon name="location-outline" color="medium" class="ion-margin-end"></ion-icon>
      <ion-text color="medium">
        <p class="font-text ion-no-margin">{{ cadastro.address | address }}</p>
      </ion-text>
    </div>
  </div>

  <div class="contrato__infos">
    <ion-item lines="full" class="ion-padding-top ion-align-items-start">
      <ion-icon name="document-text-outline" slot="start" color="secondary" class="contrato__infos__icon"></ion-icon>
      <ion-text>
        <p class="font-text">
          <ng-content select="[description-1]"></ng-content>
        </p>
      </ion-text>
    </ion-item>

    <ion-item lines="full" class="ion-padding-top ion-align-items-start">
      <ion-icon name="home-outline" slot="start" color="secondary" class="contrato__infos__icon"></ion-icon>
      <ion-text>
        <p class="font-text">
          <ng-content select="[description-2]"></ng-content>
        </p>
      </ion-text>
    </ion-item>

    <ion-item lines="none" class="ion-padding-top ion-align-items-start">
      <ion-icon name="rocket-outline" slot="start" color="secondary" class="contrato__infos__icon"></ion-icon>
      <ion-text>
        <p class="font-text">
          <ng-content select="[description-3]"></ng-content>
        </p>
      </ion-text>
    </ion-item>
  </div>



    <div class="contrato__averbacao contrato__flex ion-padding ion-margin-vertical" *ngIf="possuiAverbacao">
      <ion-checkbox mode="md" [(ngModel)]="comAverbacao"></ion-checkbox>
      <ion-text class="ion-margin-start">
        <p class="ion-no-margin font-text font-text--medium">Incluir a averbação</p>
        <p class="ion-no-margin font-text font-text--medium font-text-bold">{{ valorAverbacao | currency:
          'BRL'}}
        </p>
      </ion-text>
    </div>

    <form [formGroup]="form" class="ion-margin-top">
      <ion-text>
        <p class="font-text--medium font-text-bold">Parcelamento do pagamento por boleto:</p>
      </ion-text>

      <usucampeao-select controlName="diaEntrada" label="Preferência de entrada" *ngIf="!pagamentoAVista">
        <ion-select-option *ngFor="let diaPagamento of diasPagamento" [value]="diaPagamento">
          Dia {{ diaPagamento }}
        </ion-select-option>

        <span usu-error>{{ gerarMensagemErro('diaEntrada') }}</span>
      </usucampeao-select>

      <usucampeao-select controlName="installments" label="Número de parcelas">
        <ion-select-option *ngFor="let opcaoParcelamento of opcoesParcelamento" [value]="opcaoParcelamento.parcela">{{
          opcaoParcelamento.parcela | parcela }}</ion-select-option>

        <span usu-error>{{ gerarMensagemErro('installments') }}</span>
      </usucampeao-select>

      <usucampeao-select controlName="dueDate" label="Preferência de vencimento" *ngIf="!pagamentoAVista">
        <ion-select-option *ngFor="let diaPagamento of diasPagamento" [value]="diaPagamento">
          Todo dia {{ diaPagamento }}
        </ion-select-option>

        <span usu-error>{{ gerarMensagemErro('dueDate') }}</span>
      </usucampeao-select>

      <div class="contrato__flex contrato__flex--space-between">
        <span *ngIf="!cadastro?.cupomDescontoCodigo" class="text-link" (click)="abrirModalAdicionarCupom()">Aplicar
          cupom</span>

        <ng-container *ngIf="cadastro?.cupomDescontoCodigo">
          <p class="font-text">{{cadastro.cupomDescontoCodigo}}</p>
          <span class="text-link text-link--error" (click)="removerCupom()">Remover cupom</span>
        </ng-container>

      </div>
    </form>

    <div class="contrato__pagamento-resumo ion-margin-top" *ngIf="pagamentoSelecionado">
      <div class="contrato__flex contrato__flex--space-between">
        <ion-text>
          <p class="description-text">Entrada de</p>
        </ion-text>
        <ion-text class="contrato__container-desconto">
          <p class="contrato__valor-desconto contrato__valor-riscado" *ngIf="descontoNaEntrada">
            {{ pagamentoSelecionado.valorAtoSemDesconto | currency: 'BRL' }}
          </p>
          <p class="title">{{ valorEntrada | currency: 'BRL' }}</p>
        </ion-text>
      </div>

      <div class="contrato__flex contrato__flex--space-between" *ngIf="!pagamentoAVista">
        <ion-text>
          <p class="description-text">{{ pagamentoSelecionado.parcela | parcela }} de</p>
        </ion-text>
        <ion-text class="contrato__container-desconto">
          <p class="contrato__valor-desconto contrato__valor-riscado" *ngIf="descontoNoServico">
            {{ pagamentoSelecionado.valorParcelaSemDesconto | currency: 'BRL' }}
          </p>
          <p class="title">{{ pagamentoSelecionado.valorParcela | currency: 'BRL' }}</p>
        </ion-text>
      </div>

      <div class="contrato__divider"></div>

      <ng-container *ngIf="cadastro?.cupomDescontoCodigo">
        <div class="contrato__flex contrato__flex--space-between">
          <ion-text>
            <p class="description-text">Subtotal</p>
          </ion-text>
          <ion-text>
            <p class="contrato__valor-riscado">
              {{ pagamentoSelecionado.valorTotalSemDesconto | currency: 'BRL' }}
            </p>
          </ion-text>
        </div>

        <div class="contrato__flex contrato__flex--space-between">
          <ion-text>
            <p class="font-text font-text--small font-text--success">Descontos</p>
          </ion-text>
          <ion-text>
            <p class="font-text font-text--bold font-text--success">
              {{ valorDesconto | currency: 'BRL' }}
            </p>
          </ion-text>
        </div>
      </ng-container>

      <div class="contrato__flex contrato__flex--space-between">
        <ion-text>
          <p class="description-text">Total</p>
        </ion-text>
        <ion-text>
          <p class="title">{{ valorTotal | currency: 'BRL' }}</p>
        </ion-text>
      </div>
    </div>

  <ion-text>
    <p class="font-text">Os boletos estarão disponíveis após a assinatura do contrato.</p>
  </ion-text>
  </ng-container>

  <ion-text>
    <p class="text-link ion-margin-top" (click)="visualizarPreviaContrato()">Visualizar ou imprimir contrato de adesão
    </p>
  </ion-text>

  <div class="contrato__flex ion-margin-bottom">
    <ion-checkbox class="ion-margin-end contrato__checkbox" mode="md"
      [(ngModel)]="termosContratoAceitos"></ion-checkbox>
    <ion-text class="contrato__termos-contrato">
      <p class="font-text ion-no-margin">{{ textoTermosContrato }}</p>
    </ion-text>
  </div>

  <usucampeao-footer>
    <ion-button id="forma-pagamento-aceitar-contrato" class="ion-margin-bottom" mode="md" (click)="aceitarContrato()">{{
      textoBotaoFechamentoContrato }}</ion-button>
    <ion-button mode="md" fill="outline" (click)="voltar()">Voltar</ion-button>
  </usucampeao-footer>
</section>
