<ion-content class="ion-padding">
  <ion-refresher slot="fixed" (ionRefresh)="carregarCadastro($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <usucampeao-contrato-confirmar-informacoes [cadastro]="cadastro$ | async" [imovel]="imovel$ | async" [proprietario]="proprietario$ | async"
    [possuiPagamento]="!(projeto$ | async)?.semPagamento" [tabelaPreco]="tabelaPreco"
    (aoSolicitarPreviaContrato)="visualizarPreviaContrato()"
    (aoSolicitarAssinaturaContrato)="solicitarAssinaturaContrato($event)">
  </usucampeao-contrato-confirmar-informacoes>
</ion-content>
