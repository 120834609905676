/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { CadastroContratoAssinaturaTipoEnvioLink, CadastroContratoTipoAssinatura } from '@usucampeao/interfaces';
import {
  CadastroSelecionarPagamentosDto,
  CupomDescontoTipoDesconto,
  DiaPagamento,
  PaymentType,
  TabelaPrecoDetalhesDto,
  TabelaPrecoParcela,
  TabelaPrecoParcelaListaDto
} from '@usucampeao/lib-reurb-simplificado';
import { AbstractForm } from '@usucampeao/utils-frontend';
import { Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ModalSelecaoTipoEnvioLinkComponent, ModalSelecaoTipoEnvioLinkOutput } from '../../../components';
import { CadastroFichaDto } from '../../../domain';
import { NavigationService } from '../../../services';
import { CupomDescontoService } from '../../../services/cupom-desconto.service';
import { LoadingService, ToastService } from '../../../shared';
import { ModalAdicionarCupomDescontoComponent } from './modal-adicionar-cupom-desconto/modal-adicionar-cupom-desconto.component';
import { ModalConfirmarFechamentoContratoComponent } from './modal-confirmar-fechamento-contrato/modal-confirmar-fechamento-contrato.component';

export type FecharContratoOutput = {
  dadosPagamento: CadastroSelecionarPagamentosDto;
  tipoEnvioLink?: CadastroContratoAssinaturaTipoEnvioLink;
};
export const ASSINATURA_DIGITAL_HABILITADA = 'ASSINATURA_DIGITAL_HABILITADA';
@Component({
  selector: 'usucampeao-contrato',
  templateUrl: './contrato.component.html',
  styleUrls: ['./contrato.component.scss']
})
export class ContratoComponent extends AbstractForm implements OnInit, OnChanges, OnDestroy {
  @Input() projetoNome: string;
  @Input() possuiPagamento: boolean;
  @Input() possuiAverbacao: boolean;
  @Input() cadastro: CadastroFichaDto;
  @Input() tabelaPreco: Partial<TabelaPrecoDetalhesDto>[];
  @Input() isAgente = true;

  @Output() aoAplicarCupom = new EventEmitter<void>();
  @Output() aoDesaplicarCupom = new EventEmitter<void>();
  @Output() aoSolicitarPreviaContrato = new EventEmitter<CadastroSelecionarPagamentosDto>();
  @Output() aoFecharContrato = new EventEmitter<FecharContratoOutput>();

  private subscription$: Subscription;

  public diasPagamento = Object.values(DiaPagamento);
  public comAverbacao = false;
  public termosContratoAceitos = false;
  // variaveis criadas para salvar os valores do form após aplicar/desaplicar cupom
  // para que não sejam perdidos ao desaplicar o cupom e o form ser resetado
  private parcelaSelecionada: TabelaPrecoParcela;
  private diaEntradaSelecionadao: DiaPagamento;
  private diaVencimentoSelecionado: DiaPagamento;

  constructor(
    private alertCtrl: AlertController,
    private cupomService: CupomDescontoService,
    @Inject(ASSINATURA_DIGITAL_HABILITADA)
    private assinaturaDigitalHabilitada: boolean,
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private modalCtrl: ModalController,
    private navigationService: NavigationService,
    private toastService: ToastService,
  ) {
    super();
    this.form = this.fb.group({
      paymentType: [PaymentType.TICKET, Validators.required],
      diaEntrada: [null, Validators.required],
      installments: [null, Validators.required],
      dueDate: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.subscription$ = this.form.get('installments').valueChanges
      .pipe(
        tap(() => {
          const dataVencimentoControl = this.form.get('dueDate');
          const diaEntradaControl = this.form.get('diaEntrada');
          if (this.pagamentoAVista) {
            dataVencimentoControl.clearValidators();
            dataVencimentoControl.setValue(null);
            diaEntradaControl.clearValidators();
            diaEntradaControl.setValue(null);
          } else {
            dataVencimentoControl.setValidators(Validators.required);
            diaEntradaControl.setValidators(Validators.required);
          }

          dataVencimentoControl.updateValueAndValidity();
          diaEntradaControl.updateValueAndValidity();
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { cadastro } = changes;
    if (cadastro && cadastro.currentValue) {
      this.form.patchValue({
        diaEntrada: this.cadastro.diaEntrada ?? this.diaEntradaSelecionadao,
        installments: this.cadastro.totalParcelas?.toString() ?? this.parcelaSelecionada,
        dueDate: this.cadastro.diaVencimento ?? this.diaVencimentoSelecionado
      });
      this.comAverbacao = this.cadastro.averbacao;
    }
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  public get formasPagamento(): typeof PaymentType {
    return PaymentType;
  }

  public get tabelaPrecoSelecionada(): Partial<TabelaPrecoDetalhesDto> {
    if (!this.tabelaPreco) {
      return null;
    }

    const tabelaPrecoSelecionada = this.tabelaPreco.find((item) => item.modalidade === this.cadastro.reurbModalidade && (this.comAverbacao ? item.averbacao : !item.averbacao));
    return tabelaPrecoSelecionada ? tabelaPrecoSelecionada : this.tabelaPreco[0];
  }

  public get valorAverbacao(): number {
    return this.tabelaPrecoSelecionada?.valorAverbacao || 0;
  }

  public get valorServico(): number {
    return this.tabelaPrecoSelecionada?.valorEfetivo;
  }

  public get opcoesParcelamento(): TabelaPrecoParcelaListaDto[] {
    return this.tabelaPrecoSelecionada?.parcelas || [];
  }

  public get valorDesconto(): number {
    return this.pagamentoSelecionado?.valorDesconto;
  }

  public get valorTotal(): number {
    return this.pagamentoSelecionado?.valorTotal;
  }

  public get valorEntrada(): number {
    return this.pagamentoSelecionado?.valorAto;
  }

  public get pagamentoSelecionado(): TabelaPrecoParcelaListaDto {
    const pagamentoSelecionado = this.form?.get('installments')?.value;
    return this.tabelaPrecoSelecionada?.parcelas?.find(parcela => parcela.parcela === pagamentoSelecionado);
  }

  public get pagamentoAVista(): boolean {
    return this.pagamentoSelecionado?.parcela === TabelaPrecoParcela.PARCELA_0;
  }

  public get textoTermosContrato(): string {
    if (this.possuiPagamento) {
      if (this.isAgente) {
        return 'Atesto que o contrato e forma de pagamento foram apresentados e aceitos pelo cliente.';
      }

      return 'Aceito o contrato e a forma de pagamento selecionada.'
    }

    return 'Atesto que as informações inseridas são verdadeiras.';
  }

  public get textoBotaoFechamentoContrato(): string {
    if (this.isAgente) {
      return 'Aceite de contrato'
    }

    return 'Quero regularizar meu imóvel';
  }

  public get dadosPagamento(): CadastroSelecionarPagamentosDto {
    if (!this.possuiPagamento) {
      return null;
    }

    const formValue = Object.assign({}, this.form.value);
    return new CadastroSelecionarPagamentosDto({
      diaEntrada: formValue.diaEntrada,
      totalParcelas: this.pagamentoSelecionado.quantidadeParcela,
      diaVencimento: formValue.dueDate,
      meioPagamento: formValue.paymentType,
      averbacao: this.comAverbacao,
    });
  }

  public get descontoNaEntrada(): boolean {
    return this.cadastro?.cupomDescontoTipo === CupomDescontoTipoDesconto.ENTRADA;
  }

  public get descontoNoServico(): boolean {
    return this.cadastro?.cupomDescontoTipo === CupomDescontoTipoDesconto.SERVICO;
  }

  public async visualizarPreviaContrato(): Promise<void> {
    this.form.markAllAsTouched();
    if (this.possuiPagamento && this.form.invalid) {
      await this.toastService.warning('Selecione uma opção de pagamento antes de visualizar a prévia do contrato').toPromise();
      return;
    }
    this.aoSolicitarPreviaContrato.emit(this.dadosPagamento);
  }

  public async aceitarContrato(): Promise<void> {
    if (this.possuiPagamento) {
      this.form.markAllAsTouched();
      if (this.form.invalid) {
        await this.toastService.warning('Selecione uma opção de pagamento para fechar o contrato').toPromise();
        return;
      }
    }

    if (!this.termosContratoAceitos) {
      this.mostrarAlerta('Você precisa aceitar os termos de contração para continuar com o envio do cadastro para análise.');
      return;
    }

    this.mostrarModalConfirmarFechamentoContrato();
  }

  private async mostrarModalConfirmarFechamentoContrato(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalConfirmarFechamentoContratoComponent,
      cssClass: 'confirma-envio-modal',
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      if (
        !this.assinaturaDigitalHabilitada ||
        (this.cadastro.contratoTipoAssinatura && this.cadastro.contratoTipoAssinatura !== CadastroContratoTipoAssinatura.LINK)
      ) {
        this.aoFecharContrato.emit({ dadosPagamento: this.dadosPagamento });
      } else {
        this.mostrarModalSelecaoTipoEnvioLink();
      }
    }
  }

  private async mostrarModalSelecaoTipoEnvioLink(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalSelecaoTipoEnvioLinkComponent,
      componentProps: {
        proprietarioEmail: this.cadastro.proprietarioEmail,
        proprietarioWhatsapp: this.cadastro.proprietarioWhatsapp,
        cadastroId: this.cadastro.id,
        proprietarioId: this.cadastro.proprietarioId,
      },
      cssClass: 'modal-selecionar-tipo-envio-link'
    });

    await modal.present();
    const { data } = await modal.onDidDismiss<ModalSelecaoTipoEnvioLinkOutput>();
    if (data.confirmarSelecaoTipo) {
      this.aoFecharContrato.emit({ dadosPagamento: this.dadosPagamento, tipoEnvioLink: data.tipoEnvioLink });
    }
  }

  private async mostrarAlerta(message: string) {
    const alert = await this.alertCtrl.create({
      header: 'Atenção',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  public async abrirModalAdicionarCupom(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalAdicionarCupomDescontoComponent,
      componentProps: {
        cadastroId: this.cadastro.id,
        quantidadeParcelas: this.pagamentoSelecionado?.quantidadeParcela,
      },
      cssClass: 'modal-adicionar-cupom',
    });

    await modal.present();
    const { data } = await modal.onDidDismiss<{ cupomAplicado: boolean, resetarParcelas: boolean }>();
    if (data.cupomAplicado) {
      this.preencherValoresSelecionados(data.resetarParcelas);
      this.aoAplicarCupom.emit();
      this.toastService.success('Cupom adicionado com sucesso.').toPromise();
    }
  }

  public async removerCupom(): Promise<void> {
    await this.loadingService.createLoader();
    this.cupomService.desaplicarCupom(this.cadastro.id)
      .pipe(
        tap(() => this.preencherValoresSelecionados()),
        tap(() => this.aoDesaplicarCupom.emit()),
        catchError(error => {
          this.toastService.error('Não foi possível desaplicar o cupom de desconto');
          throw error;
        }),
        finalize(() => this.loadingService.dismiss())
      )
      .subscribe();
  }

  private preencherValoresSelecionados(resetarParcelas = false): void {
    const { diaEntrada, installments, dueDate } = this.form.value;
    this.parcelaSelecionada = resetarParcelas ? null : installments;
    this.diaEntradaSelecionadao = diaEntrada;
    this.diaVencimentoSelecionado = dueDate;
  }

  public voltar(): void {
    this.navigationService.voltar();
  }
}
